







import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import appService from "@/services/appService";
import makeSelectOptions from "@common/util/makeSelectOptions";
import { statuses } from "@/constants/ocppAutochargeFoundVehicle";

export default Vue.extend({
  data() {
    return {
      self: this,
      chargingStation: null,
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "time",
          "sort-desc": true,
        },
        content: {
          name: "Autocharge Found Vehicles",
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          filters: {
            filters: {
              tenantId: userManager.checkRole(["systemAdmin", "scAdmin"]) && {
                type: "XAutocomplete",
                attrs: {
                  label: "Organization",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "tenants",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              orderBy: "name",
                              orderType: "asc",
                              filters: [
                                {
                                  key: "_id",
                                  operator: "equal_to",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              chargingStationId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Charge Point ID",
                  required: true,
                  "item-value": "_id",
                  "item-text": "uniqueId",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "chargingStations",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              orderBy: "uniqueId",
                              orderType: "asc",
                              filters: [
                                {
                                  key: "_id",
                                  operator: "equal_to",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              connectorId: {
                attrs: {
                  label: "Connector ID",
                },
                rules: {
                  equal_to: {},
                },
              },
              vehicleId: {
                attrs: {
                  label: "Vehicle ID",
                },
                rules: {
                  equal_to: {},
                },
              },
              time: {
                type: "XTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Time (From)",
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Time (To)",
                    },
                  },
                },
              },
              status: {
                type: "selectMultiple",
                attrs: {
                  label: "Status",
                  items: makeSelectOptions(statuses),
                },
                rules: {
                  in: {},
                },
              },
            },
          },
          displayFields: {
            time: {
              text: "Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            chargingStation: {
              text: "Charge Point ID",
              options: {
                subProp: "chargingStation.uniqueId",
                sortBy: "chargingStationId",
                label: true,
              },
            },
            connectorId: {
              text: "Connector ID",
              sortable: true,
            },
            vehicleId: {
              text: "Vehicle ID",
              sortable: true,
            },
            status: {
              text: "Status",
              sortable: true,
              options: {
                transform: (value) => statuses[value],
                label: true,
              },
            },
            tenant: userManager.checkRole(["systemAdmin", "scAdmin"]) && {
              text: "Organization",
              options: {
                subProp: "tenant.name",
                label: true,
                sortBy: "tenantId",
              },
            },
            action: {
              text: "Actions",
            },
          },
          findAllOptionsFilter(options) {
            const activeTenant = appService.getActiveTenant();
            if (activeTenant) {
              options.filters.push({
                key: "tenantId",
                operator: "equal_to",
                value: activeTenant._id,
              });
            }
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call(
                "ocppAutochargeFoundVehicles",
                "findAll",
                options
              );
              return [items, count];
            },
            async update(item) {
              return await coreApiClient.call(
                "ocppAutochargeFoundVehicles",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call(
                "ocppAutochargeFoundVehicles",
                "delete",
                {
                  id: item._id,
                }
              );
            },
          },
          default: {
            defaultInputs: {
              status: {
                type: "select",
                attrs: {
                  label: "Status",
                  items: makeSelectOptions(statuses),
                },
              },
              message: {
                type: "textLarge",
                attrs: {
                  label: "Message",
                },
              },
            },
            editForm: userManager.checkRole([
              "systemAdmin",
              "scAdmin",
              "cpoAdmin",
            ]) && {
              content: {
                fieldNames: ["status", "message"],
              },
            },
            delete: {
              confirmDisplayField: "vehicleId",
            },
          },
        },
      },
    };
  },
});
